.flex-container {
    display: inline-flex;
    width: 100%;
}

.flex-container > * {
    flex-grow: 1;
}

.flex-center {
    align-items: center;
}

.center {
    align-self: center;
}

.full-width {
    width: 100%;
}

.no-flex-grow {
    flex-grow: 0;
}

.flex-space-between {
    align-content: space-between;
    flex-wrap: wrap;
}


.ellipsis {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    /* autoprefixer: off */
}